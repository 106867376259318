import * as qiniu from 'qiniu-js'
import * as UUID from 'uuidjs'
import { GetOauthUpToken } from '@/apis/resource'
/* eslint-disable no-console */
/** 上传文件
 * @param  {String} url 上传路径
 * @param  {FormData} formData  上传的formData对象
 * @param  {Function} onprogress 不断触发上传进度的函数
 * @return  {String} responseText
 */
export function uploadFileXhr(url, formData, onprogress = function() {}) {
  return new Promise((resolve, reject) => {
    let xhr = new window.XMLHttpRequest()
    xhr.upload.addEventListener('progress', onprogress, false)
    xhr.addEventListener(
      'load',
      function() {
        console.log('load')
      },
      false
    )
    xhr.addEventListener(
      'error',
      function(e) {
        console.log(`error ${e}`)
      },
      false
    )
    xhr.addEventListener(
      'abort',
      function() {
        console.log('abort')
      },
      false
    )
    xhr.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 200) {
          try {
            let response = JSON.parse(this.responseText)
            if (response.status === 200) {
              resolve(response.data)
            } else {
              reject(response)
            }
          } catch (e) {
            resolve(this.responseText)
          }
        } else {
          reject(this.responseText)
        }
      }
    }
    xhr.open('POST', url)
    xhr.send(formData)
  })
}

export function uploadFile(
  url = '/resource/home/upload',
  { files, onprogress }
) {
  let formData = new window.FormData()
  files.forEach(file => formData.append('file_base[]', file))
  return uploadFileXhr(url, formData, onprogress)
}

export function uploadQiniu(
  url = 'http://upload.qiniu.com/',
  { params, onprogress }
) {
  let formData = new window.FormData()
  let keys = Object.keys(params)
  keys.forEach(key => {
    formData.append(key, params[key])
  })
  return uploadFileXhr(url, formData, onprogress)
}

export function addZero(val) {
  return val < 10 ? '0' + val : val + ''
}

export function getYMD() {
  var date = new Date()
  return (
    date.getFullYear() + addZero(date.getMonth() + 1) + addZero(date.getDate())
  )
}

export function getDays() {
  var date = new Date()
  return (
    date.getFullYear() +
    addZero(date.getMonth() + 1) +
    addZero(date.getDate()) +
    addZero(date.getHours()) +
    addZero(date.getMinutes())
  )
}

export function getPrefix(str) {
  // 获取文件名字
  var start = str.lastIndexOf('.')
  return str.slice(0, start)
}
export function getSuffix(str) {
  var start = str.lastIndexOf('.')
  return str.slice(start)
}
export async function qiniuUpload({ file, progress }) {
  const { uptoken } = await GetOauthUpToken()
  const uuid = UUID.generate()
  let putExtra = {
    fname: file.name,
    params: {},
    mimeType: null
  }
  let key = `xfarm/bi/2020/${getPrefix(file.name)}${uuid}${getSuffix(
    file.name
  )}`
  let config = {}
  const observable = qiniu.upload(file, key, uptoken, putExtra, config)
  return new Promise((resolve, reject) => {
    observable.subscribe({
      next(res) {
        progress && progress(res)
      },
      error(err) {
        // ...
        reject(err)
      },
      complete(res) {
        // ...
        resolve(res)
      }
    })
  })
}
